import { GLTFNodes } from 'src/sections/Mouth/types';
import { BufferGeometry, Euler, NormalBufferAttributes, Vector3 } from 'three';

interface Tooth {
	id: number;
	base: ToothDetail;
	beveled?: ToothDetail;
	diamond?: ToothDetail;
	window?: ToothDetail;
	windowDiamond?: ToothDetail;
}

interface ToothDetail {
	element: BufferGeometry<NormalBufferAttributes>;
	position?: Vector3;
	scale: Vector3;
	rotation?: Euler;
}


export const generateTeeth = (nodes: GLTFNodes): Tooth[] => (
	[
		{
			id: 11,
			base: {
				element: nodes.tooth_11.geometry,
				position: [-3.692, 6.548, 22.15] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
			diamond: {
				element: nodes.tooth_11_diamond.geometry,
				position: [-4.228, 11.409, 22.38] as unknown as Vector3,
				rotation: [1.392, -1.092, 0.428] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
			window: {
				element: nodes.tooth_11_window.geometry,
				position: [-3.692, 6.548, 22.15] as unknown as Vector3,
				scale: 1.018 as unknown as Vector3
			},
			windowDiamond: {
				element: nodes.tooth_11_window_diamond.geometry,
				position: [-4.623, 11.797, 22.158] as unknown as Vector3,
				rotation: [1.229, -0.844, 0.327] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
		},
		{
			id: 12,
			base: {
				element: nodes.tooth_12.geometry,
				position: [-9.813, 5.898, 18.369] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
			diamond: {
				element: nodes.tooth_12_diamond.geometry,
				position: [-12.858, 7.993, 18.17] as unknown as Vector3,
				rotation: [2.444, -0.467, 1.393] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
			window: {
				element: nodes.tooth_12_window.geometry,
				position: [-9.813, 5.898, 18.369] as unknown as Vector3,
				scale: 1.03 as unknown as Vector3
			},
			windowDiamond: {
				element: nodes.tooth_12_window_diamond.geometry,
				position: [-8.954, 8.807, 20.284] as unknown as Vector3,
				rotation: [0.662, -0.863, -0.279] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
		},
		{
			id: 13,
			base: {
				element: nodes.tooth_13.geometry,
				position: [-14.721, 4.831, 12.838] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
			beveled: {
				element: nodes.tooth_13_beveled.geometry,
				position: [-14.721, 4.831, 12.838] as unknown as Vector3,
				scale: 1.009 as unknown as Vector3
			},
			diamond: {
				element: nodes.tooth_13_diamond.geometry,
				position: [-16.285, 8.27, 15.073] as unknown as Vector3,
				rotation: [2.597, -0.701, 1.456] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
			window: {
				element: nodes.tooth_13_window.geometry,
				position: [-14.347, 4.714, 12.482] as unknown as Vector3,
				scale: 1.016 as unknown as Vector3
			},
			windowDiamond: {
				element: nodes.tooth_13_window_diamond.geometry,
				position: [-13.85, 6.415, 16.715] as unknown as Vector3,
				rotation: [0.776, -0.957, -0.498] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
		},
		{
			id: 14,
			base: {
				element: nodes.tooth_14.geometry,
				position: [-16.472, 3.221, 5.383] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
			diamond: {
				element: nodes.tooth_14_diamond.geometry,
				position: [-19.771, 6.398, 7.113] as unknown as Vector3,
				rotation: [3.043, -0.677, 1.53] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
			window: {
				element: nodes.tooth_14_window.geometry,
				position: [-16.472, 3.221, 5.383] as unknown as Vector3,
				scale: 1.011 as unknown as Vector3
			},
			windowDiamond: {
				element: nodes.tooth_14_window_diamond.geometry,
				position: [-17.859, 4.821, 9.091] as unknown as Vector3,
				rotation: [1.43, -1.113, 0.332] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
		},
		{
			id: 15,
			base: {
				element: nodes.tooth_15.geometry,
				position: [-19.811, 2.767, -1.05] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
			diamond: {
				element: nodes.tooth_15_diamond.geometry,
				position: [-23.487, 5.861, -0.448] as unknown as Vector3,
				rotation: [2.549, 0.013, 1.702] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
			window: {
				element: nodes.tooth_15_window.geometry,
				position: [-19.811, 2.767, -1.05] as unknown as Vector3,
				scale: 1.01 as unknown as Vector3
			},
			windowDiamond: {
				element: nodes.tooth_15_window_diamond.geometry,
				position: [-20.935, 3.214, 3.086] as unknown as Vector3,
				rotation: [1.844, -1.067, 0.744] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
		},
		{
			id: 16,
			base: {
				element: nodes.tooth_16.geometry,
				position: [-22.696, 2.601, -8.709] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
		},
		{
			id: 17,
			base: {
				element: nodes.tooth_17.geometry,
				position: [-25.928, 2.912, -17.888] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
		},
		{
			id: 21,
			base: {
				element: nodes.tooth_21.geometry,
				position: [5.243, 6.697, 21.849] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
			diamond: {
				element: nodes.tooth_21_diamond.geometry,
				position: [6.162, 11.569, 22.153] as unknown as Vector3,
				rotation: [0.942, -0.038, -0.316] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
			window: {
				element: nodes.tooth_21_window.geometry,
				position: [5.243, 6.697, 21.849] as unknown as Vector3,
				scale: 1.053 as unknown as Vector3
			},
			windowDiamond: {
				element: nodes.tooth_21_window_diamond.geometry,
				position: [7.007, 11.625, 21.819] as unknown as Vector3,
				rotation: [0.866, 0.052, -0.463] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
		},
		{
			id: 22,
			base: {
				element: nodes.tooth_22.geometry,
				position: [11.186, 6.133, 18.209] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
			diamond: {
				element: nodes.tooth_22_diamond.geometry,
				position: [11.03, 8.943, 19.796] as unknown as Vector3,
				rotation: [0.785, -0.8, -0.205] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
			window: {
				element: nodes.tooth_22_window.geometry,
				position: [11.186, 6.133, 18.209] as unknown as Vector3,
				scale: 1.045 as unknown as Vector3
			},
			windowDiamond: {
				element: nodes.tooth_22_window_diamond.geometry,
				position: [14.561, 8.201, 17.035] as unknown as Vector3,
				rotation: [0.355, -0.029, -1.138] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
		},
		{
			id: 23,
			base: {
				element: nodes.tooth_23.geometry,
				position: [15.955, 4.841, 12.855] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
			beveled: {
				element: nodes.tooth_23_beveled.geometry,
				scale: 1.007 as unknown as Vector3
			},
			diamond: {
				element: nodes.tooth_23_diamond.geometry,
				position: [18.389, 8.432, 12.809] as unknown as Vector3,
				rotation: [0.497, -0.259, -1.304] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
			window: {
				element: nodes.tooth_23_window.geometry,
				position: [15.362, 4.679, 12.647] as unknown as Vector3,
				scale: 1.001 as unknown as Vector3

			},
			windowDiamond: {
				element: nodes.tooth_23_window_diamond.geometry,
				position: [18.798, 8.783, 12.189] as unknown as Vector3,
				rotation: [0.582, -0.203, -1.507] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
		},
		{
			id: 24,
			base: {
				element: nodes.tooth_24.geometry,
				position: [17, 3.22, 5.89] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
			diamond: {
				element: nodes.tooth_24_diamond.geometry,
				position: [21.073, 6.457, 6.278] as unknown as Vector3,
				rotation: [0.489, -0.382, -1.4] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
			window: {
				element: nodes.tooth_24_window.geometry,
				position: [17, 3.22, 5.89] as unknown as Vector3,
				scale: 1.003 as unknown as Vector3
			},
			windowDiamond: {
				element: nodes.tooth_24_window_diamond.geometry,
				position: [21.389, 6.706, 5.811] as unknown as Vector3,
				rotation: [0.57, -0.162, -1.482] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
		},
		{
			id: 25,
			base: {
				element: nodes.tooth_25.geometry,
				position: [20.371, 2.686, -0.462] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
			diamond: {
				element: nodes.tooth_25_diamond.geometry,
				position: [23.66, 5.991, 1.022] as unknown as Vector3,
				rotation: [0.643, -0.434, -0.967] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
			window: {
				element: nodes.tooth_25_window.geometry,
				position: [20.371, 2.686, -0.462] as unknown as Vector3,
				scale: 1.012 as unknown as Vector3
			},
			windowDiamond: {
				element: nodes.tooth_25_window_diamond.geometry,
				position: [24.199, 6.58, 0.147] as unknown as Vector3,
				rotation: [0.431, -0.172, -1.23] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
		},
		{
			id: 26,
			base: {
				element: nodes.tooth_26.geometry,
				position: [22.243, 1.988, -8.756] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
		},
		{
			id: 27,
			base: {
				element: nodes.tooth_27.geometry,
				position: [25.888, 2.06, -17.699] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
		},
		{
			id: 31,
			base: {
				element: nodes.tooth_31.geometry,
				position: [2.986, 0.423, 20.33] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
			diamond: {
				element: nodes.tooth_31_diamond.geometry,
				position: [4.407, 2.958, 21.722] as unknown as Vector3,
				rotation: [0.937, -1.046, -0.499] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
			window: {
				element: nodes.tooth_31_window.geometry,
				position: [2.986, 0.423, 20.33] as unknown as Vector3,
				scale: 1.026 as unknown as Vector3
			},
			windowDiamond: {
				element: nodes.tooth_31_window_diamond.geometry,
				position: [5.031, 2.879, 21.688] as unknown as Vector3,
				rotation: [0.669, -0.81, -1.002] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
		},
		{
			id: 32,
			base: {
				element: nodes.tooth_32.geometry,
				position: [7.897, 0.197, 18.272] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
			diamond: {
				element: nodes.tooth_32_diamond.geometry,
				position: [9.89, 2.368, 18.777] as unknown as Vector3,
				rotation: [0.625, -0.772, -0.813] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
			window: {
				element: nodes.tooth_32_window.geometry,
				position: [7.897, 0.197, 18.272] as unknown as Vector3,
				scale: 1.023 as unknown as Vector3
			},
			windowDiamond: {
				element: nodes.tooth_32_window_diamond.geometry,
				position: [8.076, -3.054, 21.481] as unknown as Vector3,
				rotation: [0.837, -1.02, -0.78] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
		},
		{
			id: 33,
			base: {
				element: nodes.tooth_33.geometry,
				position: [13.015, -1.252, 17.718] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
			beveled: {
				element: nodes.tooth_33_beveled.geometry,
				position: [13.015, -1.252, 17.718] as unknown as Vector3,
				scale: 1.012 as unknown as Vector3
			},
			diamond: {
				element: nodes.tooth_33_diamond.geometry,
				position: [13.534, 1.906, 19.464] as unknown as Vector3,
				rotation: [0.554, -0.697, -0.807] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
			window: {
				element: nodes.tooth_33_window.geometry,
				position: [12.598, -1.334, 17.295] as unknown as Vector3,
				scale: 1.009 as unknown as Vector3
			},
			windowDiamond: {
				element: nodes.tooth_33_window_diamond.geometry,
				position: [14.406, 2.893, 18.3] as unknown as Vector3,
				rotation: [0.421, -0.51, -1] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
		},
		{
			id: 34,
			base: {
				element: nodes.tooth_34.geometry,
				position: [15.56, -1.592, 10.839] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
			diamond: {
				element: nodes.tooth_34_diamond.geometry,
				position: [15.808, 0.518, 13.826] as unknown as Vector3,
				rotation: [0.503, -0.68, -0.695] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
			window: {
				element: nodes.tooth_34_window.geometry,
				position: [15.56, -1.592, 10.839] as unknown as Vector3,
				scale: 1.022 as unknown as Vector3
			},
			windowDiamond: {
				element: nodes.tooth_34_window_diamond.geometry,
				position: [17.793, -3.438, 14.196] as unknown as Vector3,
				rotation: [0.952, -1.077, -0.725] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
		},
		{
			id: 35,
			base: {
				element: nodes.tooth_35.geometry,
				position: [17.917, -1.993, 4.22] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
			diamond: {
				element: nodes.tooth_35_diamond.geometry,
				position: [18.773, -1.607, 7.626] as unknown as Vector3,
				rotation: [1.384, -0.806, -0.143] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
			window: {
				element: nodes.tooth_35_window.geometry,
				position: [17.917, -1.993, 4.22] as unknown as Vector3,
				scale: 1.016 as unknown as Vector3
			},
			windowDiamond: {
				element: nodes.tooth_35_window_diamond.geometry,
				position: [22.43, -2.198, 2.473] as unknown as Vector3,
				rotation: [0.585, 0.498, -1.635] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
		},
		{
			id: 36,
			base: {
				element: nodes.tooth_36.geometry,
				position: [21.393, -2.847, -4.124] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
		},
		{
			id: 37,
			base: {
				element: nodes.tooth_37.geometry,
				position: [23.696, -3.331, -14.443] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
		},
		{
			id: 41,
			base: {
				element: nodes.tooth_41.geometry,
				position: [-2.084, 0.29, 20.862] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
			diamond: {
				element: nodes.tooth_41_diamond.geometry,
				position: [-0.997, 2.856, 22.407] as unknown as Vector3,
				rotation: [1.158, -1.124, -0.279] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
			window: {
				element: nodes.tooth_41_window.geometry,
				position: [-2.084, 0.29, 20.862] as unknown as Vector3,
				scale: 1.014 as unknown as Vector3
			},
			windowDiamond: {
				element: nodes.tooth_41_window_diamond.geometry,
				position: [-0.337, 3.205, 22.533] as unknown as Vector3,
				rotation: [0.956, -1.056, -0.505] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
		},
		{
			id: 42,
			base: {
				element: nodes.tooth_42.geometry,
				position: [-5.96, 0.372, 18.524] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
			diamond: {
				element: nodes.tooth_42_diamond.geometry,
				position: [-5.607, -3.038, 21.461] as unknown as Vector3,
				rotation: [1.469, -1.217, -0.202] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
			window: {
				element: nodes.tooth_42_window.geometry,
				position: [-5.96, 0.372, 18.524] as unknown as Vector3,
				scale: 1.039 as unknown as Vector3
			},
			windowDiamond: {
				element: nodes.tooth_42_window_diamond.geometry,
				position: [-7.725, 2.734, 19.464] as unknown as Vector3,
				rotation: [2.274, -0.992, 1.103] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
		},
		{
			id: 43,
			base: {
				element: nodes.tooth_43.geometry,
				position: [-11.185, -0.646, 18.142] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
			beveled: {
				element: nodes.tooth_43_beveled.geometry,
				position: [-11.185, -0.646, 18.142] as unknown as Vector3,
				scale: 1.044 as unknown as Vector3
			},
			diamond: {
				element: nodes.tooth_43_diamond.geometry,
				position: [-10.713, 2.418, 20.771] as unknown as Vector3,
				rotation: [2.337, -0.81, 1.469] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
			window: {
				element: nodes.tooth_43_window.geometry,
				position: [-10.759, -0.552, 17.754] as unknown as Vector3,
				scale: 1.009 as unknown as Vector3
			},
			windowDiamond: {
				element: nodes.tooth_43_window_diamond.geometry,
				position: [-9.882, 2.109, 21.642] as unknown as Vector3,
				rotation: [1.566, -1.162, 0.078] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
		},
		{
			id: 44,
			base: {
				element: nodes.tooth_44.geometry,
				position: [-13.639, -0.551, 11.573] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
			diamond: {
				element: nodes.tooth_44_diamond.geometry,
				position: [-14.667, -0.787, 14.715] as unknown as Vector3,
				rotation: [2.059, -1.135, 0.259] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
			window: {
				element: nodes.tooth_44_window.geometry,
				position: [-13.639, -0.551, 11.573] as unknown as Vector3,
				scale: 1.01 as unknown as Vector3
			},
			windowDiamond: {
				element: nodes.tooth_44_window_diamond.geometry,
				position: [-14.029, 1.035, 15.373] as unknown as Vector3,
				rotation: [1.271, -1.222, -0.296] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
		},
		{
			id: 45,
			base: {
				element: nodes.tooth_45.geometry,
				position: [-17.041, -1.144, 5.055] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
			diamond: {
				element: nodes.tooth_45_diamond.geometry,
				position: [-18.656, -1.469, 7.929] as unknown as Vector3,
				rotation: [2.366, -1.106, 0.957] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
			window: {
				element: nodes.tooth_45_window.geometry,
				position: [-17.041, -1.144, 5.055] as unknown as Vector3,
				scale: 1.006 as unknown as Vector3
			},
			windowDiamond: {
				element: nodes.tooth_45_window_diamond.geometry,
				position: [-16.901, -0.356, 8.666] as unknown as Vector3,
				rotation: [2.065, -1.201, 0.565] as unknown as Euler,
				scale: 1.212 as unknown as Vector3
			},
		},
		{
			id: 46,
			base: {
				element: nodes.tooth_46.geometry,
				position: [-20.503, -1.263, -3.815] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
		},
		{
			id: 47,
			base: {
				element: nodes.tooth_47.geometry,
				position: [-24.718, -1.516, -13.915] as unknown as Vector3,
				scale: 1 as unknown as Vector3
			},
		},
	]
);